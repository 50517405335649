@charset 'utf-8';

@import 'lib/base-mixins';

html, body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  color: #B0BEC5;
  display: table;
  font-weight: 100;
  font-family: sans-serif;
}

.container {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.content {
  text-align: center;
  display: inline-block;
}

.title {
  font-size: 52px;
  margin-bottom: 40px;
}

.customtext {
  font-size: 20px;
}

a {
  color: #828d94;
}

a:hover {
  color: #B0BEC5;
}
